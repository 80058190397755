import React, {Fragment} from 'react';

const Header = () => {
  return (
    <Fragment>
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end">
              <h1 className="text-uppercase text-white font-weight-bold">
                I'm a full-stack developer
              </h1>
              <hr className="divider my-4" />
            </div>
            <div className="col-lg-8 align-self-baseline">
              <p className="text-white-75 font-weight-light mb-5">
                {' '}I build apps using MongoDB, Express, React, and NodeJS
              </p>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
