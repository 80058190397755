import React, {Fragment} from 'react';

export const About = () => {
  return (
    <Fragment>
      <section className="page-section bg-primary" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-white mt-0">Skills</h2>
              <hr className="divider light my-4" />
              <p className="text-white-50 mb-4">
                JavaScript, React, jQuery<br />NodeJS, ASP, SQL
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;