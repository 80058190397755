import React, { Fragment } from 'react';
import Nav from './components/Nav';
import Header from './components/Header';
import About from './components/About';
// import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Fragment>
      <Nav />
      <Header />
      <About />
      {/* <Portfolio /> */}
      <Contact />
      <Footer />
    </Fragment>  
  );
}

export default App;
